import './App.css';
import Navbar from './components/Navbar/Navbar';
import Sensors from './components/Sensors/Sensors';


const data = {
  sensors: [
    {
      sensorName: "Cold Side",
      temperature: 22,
      humidity: 30
    },
    {
      sensorName: "Middle",
      temperature: 24,
      humidity: 30
    },
    {
      sensorName: "Hot Side",
      temperature: 29,
      humidity: 27
    }

  ]
};
// This will loop through each sensor in the data and
// return a jsx element for each one. Since its a `map`,
// it will take each `return` and put it in the new sensorList array.
const sensorList = data.sensors.map(sensor => {
  return (
    <div className="sensor-box">

      <div className="box-title">{sensor.sensorName}</div>

      Temp: {sensor.temperature}c<br />
      Humidity: {sensor.humidity}%h<br />
    </div>
  );
});


function App() {
  const title = "lizard wizard";

  return (
    <div className="main">
      <Navbar
        title={title}
      />
      <p>is your lizard comfy?</p>
      <Sensors sensors={sensorList} />

    </div>
  );
}



export default App;