import "./sensors.css";






const Sensors = (props) => {

  return (
    <div className="sensors">
      <div className="boxes">

        {props.sensors}



      </div>
    </div>
  );
};

export default Sensors;