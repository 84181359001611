import "./navbar.css";
import logo from "./lizard.png";



const Navbar = (props) => {

  return (
    <div className="navbar">

      <a href="/"><img src={logo} alt="logo" style={{ height: 50, paddingLeft: 20 }} /></a>
      <div className="title">
        {props.title}
      </div>
      <div className="links">
        <a href="/">home</a>
        <a href="/">about</a>
        <a href="/">history</a>
      </div>

    </div>
  );
};

export default Navbar;
